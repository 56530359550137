import { Hexagon } from "@/ui/Hexagon";
import cn from "classnames";

import { Card } from "react-bootstrap";
import classes from "./styles.module.scss";

export default function ({
  className,
  title,
  employment_type,
  external_reference,
  salary_package,
  featured = false,
  location,
  url_slug = "",
}) {
  return (
    <Hexagon className={cn(className, "bg-navy", classes.JobCard, { featured })} alt>
      <a href={`/jobs/${url_slug}/`} className="p-1">
        <div className={classes.JobCard__wrapper}>
          <div className="h-100 w-100 p-1">
            <Card className={cn(classes.JobCard__inner, { [classes.JobCard__featured]: featured })}>
              <Card.Header>
                {featured && (
                  <>
                    <i /> Featured Job
                  </>
                )}
              </Card.Header>
              <Card.Body>
                <div>
                  <h5 className="mt-1 mb-2">{title}</h5>
                  <div className="d-flex gap-2">
                    <i className="xsmall">{employment_type?.name}</i>
                    <i className="xsmall">{external_reference}</i>
                  </div>
                </div>
                <div>
                  <b>{salary_package}</b>
                  <address>{location}</address>
                </div>
              </Card.Body>
              <Card.Footer>
                <span>View Job Info</span>
              </Card.Footer>
            </Card>
          </div>
        </div>
      </a>
    </Hexagon>
  );
}